class CapslockChecker{
    constructor(capslockWarning, passwordInput){
        this.passwordInput = passwordInput;
        this.capslockWarning = capslockWarning

        passwordInput.addEventListener('keyup', e => this.toggleWarning(e));
    }

    toggleWarning(event) {
        const capslockState = event.getModifierState && event.getModifierState('CapsLock');
        this.capslockWarning.style = capslockState ? '': 'display:none';
    }
}

export default CapslockChecker