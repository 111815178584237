// Constants
const BODY_CLASS = 'modal--is-open';
const CLOSE_BUTTON_SELECTOR = '.modal__navigation--close';
const TRIGGER = '.open-modal';
const MODAL_WINDOW = '.modal__window';

function getArrayOfElements(selector, root = document) {
    const nodes = root.querySelectorAll(selector);
    const array = Array.prototype.slice.call(nodes);
    return array;
}

class Modal {
    constructor(modal) {
        this.modal = modal;
        this.bindShow();
        this.bindClose();
    }

    bindShow() {
        const triggers = getArrayOfElements(TRIGGER);
        triggers.forEach(trigger => {
            trigger.addEventListener('click', (e) => this.openModal(e));
            trigger.addEventListener('touchend', (e) => this.openModal(e));
        });
    }

    bindClose() {
        const closeButtons = getArrayOfElements(CLOSE_BUTTON_SELECTOR, this.modal);
        closeButtons.forEach(closeButton => {
            closeButton.addEventListener('click', () => this.hideModal());
            closeButton.addEventListener('touchend', (e) => this.hideModal(e));
        });
    }

    openModal(event) {
        event.preventDefault();
        document.body.classList.add(BODY_CLASS);
        const { target } = event;
        const id = target.getAttribute('href');
        const modal = document.querySelector(`${id}`);
        modal.style.display = 'block';
    }

    hideModal() {
        document.body.classList.remove(BODY_CLASS);
        const windows = getArrayOfElements(MODAL_WINDOW);
        windows.forEach(window => {
            window.style.display = 'none';
        });
    }
}

export default Modal;
