// Constants
const INPUT_ERROR_CLASS = 'c-field--error';

class FormValidation {
    constructor() {
        this.inputs = Array.prototype.slice.call(document.querySelectorAll('input'));
        this.setValidation();
    }

    setValidation() {
        const { inputs } = this;
        inputs.forEach((input) => this.addValidityCheck(input))
    }

    addValidityCheck(input) {
        const parent = input.parentNode;
        // If invalid add class
        input.addEventListener('invalid', e => {
            e.preventDefault();
            parent.classList.add(INPUT_ERROR_CLASS);
        });

        // If valid remove error class
        input.addEventListener('input', () => {
            if (input.validity.valid) {
                parent.classList.remove(INPUT_ERROR_CLASS)
            }
        })
    }
}

export default FormValidation;
