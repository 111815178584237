// Utilities
import Modal from './Modal';
import FormValidation from './FormValidation';
import TogglePassword from './TogglePassword';
import RadioToggle from './RadioToggle';
import CapslockChecker from './CapslockChecker'

// Styles
import '../scss/main.scss';

// Constants
const TOGGLE_SELECTOR = '#password-toggle';
const TOGGLE_SELECTOR_NEW = '#password-toggle-new';
const PASSWORD_SELECTOR = '#j_password';
const PASSWORD_SELECTOR_NEW = '#j_password_new';
const MODAL_SELECTOR = '.modal';
const MODAL_TRIGGER_SELECTOR = '#show-modal';
const RADIO_SELECTOR = '#radio-group';
const CAPSLOCK_WARNING = '#capslock-warning';

(function index() {

    const setupRadio = () => {
        const radioContainer = document.querySelector(RADIO_SELECTOR);

        if (radioContainer) {
            new RadioToggle(radioContainer);
        }
    }

    const setupModal = () => {
        const modal = document.querySelector(MODAL_SELECTOR);
        if (modal) {
            new Modal(modal);
        }
    }

    const setupPasswordToggle = () => {
        const toggle = document.querySelector(TOGGLE_SELECTOR);
        const passwordInput = document.querySelector(PASSWORD_SELECTOR);

        const toggle_new = document.querySelector(TOGGLE_SELECTOR_NEW);
        const passwordInput_new = document.querySelector(PASSWORD_SELECTOR_NEW);

        if (toggle && passwordInput) {
            new TogglePassword(toggle, passwordInput);
        }

        if (toggle_new && passwordInput_new) {
            new TogglePassword(toggle_new, passwordInput_new);
        }

    }

    const setupCapslockChecker = () => {
        const capslockWarning = document.querySelector(CAPSLOCK_WARNING);
        const passwordInput = document.querySelector(PASSWORD_SELECTOR);

        if(capslockWarning && passwordInput){
            new CapslockChecker(capslockWarning, passwordInput)
        }
    }
    const boot = () => {
        // Setup custom form validation
        new FormValidation();
        // Toggle password
        setupPasswordToggle();
        // Privacy modal
        setupModal();
        // Radio inputs
        setupRadio();
        // Capslock Checker
        setupCapslockChecker();
    };
    document.addEventListener('DOMContentLoaded', boot);
})();