class RadioToggle {
    constructor(radioContainer) {
        this.radios = Array.prototype.slice.call(radioContainer.querySelectorAll('input[type=radio]'));

        if (!this.radios) return;
        this.radios.forEach(radio => {
            radio.addEventListener('change', () => this.handleChange());
        });
    }

    handleChange() {
        this.radios.forEach(radio => {
            const { checked } = radio;
            const group = radio.closest('.group');
            const checkboxInputs = Array.prototype.slice.call(group.querySelectorAll('input[type=checkbox]'));
            this.toggleDisabled(!checked, checkboxInputs);
        });
    }

    toggleDisabled(boolean, checkboxes) {
        checkboxes.forEach(checkbox => {
            checkbox.disabled = boolean
        });
    }
}

export default RadioToggle;

// https://developer.mozilla.org/en-US/docs/Web/API/Element/closest
if (!Element.prototype.matches) {
    Element.prototype.matches = Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector;
}

if (!Element.prototype.closest) {
    Element.prototype.closest = function(s) {
        var el = this;
    
        do {
            if (el.matches(s)) return el;
            el = el.parentElement || el.parentNode;
        } while (el !== null && el.nodeType === 1);
        return null;
    };
}