class TogglePassword {
    constructor(toggle, passwordInput) {
        this.toggle = toggle;
        this.passwordInput = passwordInput;

        toggle.addEventListener('change', e => this.togglePassword(e));
    }

    togglePassword(event) {
        const { target } = event;
        this.passwordInput.type = target.checked ? 'text' : 'password';
    }
}

export default TogglePassword;
